import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, forwardRef } from "react";
import { cn } from "../../helpers/styles";

export type ButtonProps = ComponentPropsWithoutRef<"button"> &
	VariantProps<typeof buttonVariants>;

/**
 * Button component that provides a consistent look and feel for buttons
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, children, ...props }, ref) => {
		return (
			<button
				className={cn(
					buttonVariants({
						variant,
						size,
					}),
					className,
				)}
				ref={ref}
				type={props.type || "button"}
				{...props}
			>
				{children}
			</button>
		);
	},
);

Button.displayName = "Button";

export const buttonVariants = cva(
	[
		"inline-flex items-center justify-center whitespace-nowrap rounded-sm font-medium text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	],
	{
		variants: {
			// Variant determines the colors
			variant: {
				primary:
					"bg-brandGreen font-bold text-white shadow-1 hover:shadow-2 focus:ring-accent-level-2",
				secondary:
					"bg-gray-50 shadow-1 hover:border-gray-300 hover:bg-gray-50 focus:ring-accent-level-2",
				tertiary: "text-gray-600 hover:bg-gray-50 focus:ring-accent-level-2",
				link: "text-black hover:text-gray-500 hover:no-underline focus:bg-orange-dark-200",
				outline:
					"bg-white font-semibold shadow-1 ring-2 ring-accent-level-2 ring-black hover:border-gray-300 hover:bg-gray-50",
			},
			size: {
				lg: "gap-sm px-4 py-2.5 text-sm md:px-6 md:py-3 md:text-base",
				xl: "gap-sm p-2 py-lg text-md",
				xxl: "gap-lg px-[22px] py-xl text-lg",
			},
		},
		defaultVariants: {
			variant: "primary",
			size: "lg",
		},
		compoundVariants: [
			{
				variant: "link",
				size: ["lg", "xl", "xxl"],
				className: "p-0 md:p-0",
			},
		],
	},
);

/**
 * Small helper function to generate button styles based on CVA variants
 * @returns compiled classnames
 */
export const buttonStyles = (
	props: VariantProps<typeof buttonVariants>,
): string => buttonVariants(props);
