import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Classname helper that merges classNames together.
 *
 * Shared implementation of tailwind-merge and clsx. See individual libraries for details.
 */
export function cn(...inputs: ClassValue[]): string {
	return twMerge(clsx(inputs));
}
