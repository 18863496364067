import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

// Any changes made in the styling should be also be applied in the global.css
const typographyVariants = cva("", {
	variants: {
		type: {
			h1: "scroll-m-20 font-extrabold font-geologica text-4xl tracking-tight md:text-5xl lg:text-6xl",
			h2: "scroll-m-20 border-b pb-2 font-semibold text-3xl tracking-tight transition-colors first:mt-0 md:text-4xl lg:text-5xl",
			h3: "scroll-m-20 font-geologica font-semibold text-2xl tracking-tight md:text-3xl lg:text-4xl",
			h4: "scroll-m-20 font-bold text-xl tracking-tight md:text-2xl lg:text-3xl",
			h5: "scroll-m-20 font-semibold text-lg tracking-tight md:text-xl lg:text-2xl",
			h6: "scroll-m-20 font-semibold text-base tracking-tight md:text-lg lg:text-xl",
			h7: "font-semibold text-sm tracking-tight md:text-base lg:text-lg",
			p: "text-sm leading-7 md:text-base [&:not(:first-child)]:mt-6",
			blockquote: "mt-6 border-l-2 pl-6 italic",
			inlineCode:
				"relative rounded bg-gray-50 px-[0.3rem] py-[0.2rem] font-mono font-semibold text-sm",
			lead: "text-base text-gray-600 md:text-lg lg:text-xl",
			large: "font-semibold text-lg",
			small: "font-medium text-xs leading-none md:text-sm",
			muted: "text-gray-600 text-sm",
		},
	},
});

/**
 * Helper function to get the correct typography variant based on the design system
 *
 * This alleviates some of the boilerplate needed in CVA to get the correct variant
 * @returns className combinations for typography based on design system
 */
export const typography = (
	type: VariantProps<typeof typographyVariants>["type"],
): string => typographyVariants({ type });
