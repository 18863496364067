"use client"; // Error components must be Client Components

import { Button } from "@commerce-frontend/ui/base/Button";
import { typography } from "@commerce-frontend/ui/base/Typography/typography";
import { useEffect } from "react";

// biome-ignore lint: lint/suspicious/noShadowRestrictedNames
export default function Error({
	error,
	reset,
}: {
	error: Error;
	reset: () => void;
}) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
	}, [error]);

	return (
		<body>
			<div className="container flex flex-col items-center justify-center gap-y-4 py-6">
				<h2 className={typography("h2")}>Something went wrong!</h2>
				<Button
					onClick={
						// Attempt to recover by trying to re-render the segment
						() => reset()
					}
				>
					Try again
				</Button>
			</div>
		</body>
	);
}
